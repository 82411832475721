<template>
    <div>
        <div>
            <div class="drawNumbers-container winning-numbers-archive-wrapper">
                <WNArchivePicker></WNArchivePicker>
            </div>

            <div v-if="showError">
                <h1>Winning Numbers for {{ date }}</h1>
                <p>We couldn't find any results for the date selected. Please try again.</p>
            </div>
        </div>

        <div class="winning-numbers-wrapper winning-landing-archive">

            <h2 class="archive-header" v-if="(showTitle > 0)">
                Winning Numbers for <span class="archive-date">{{ date }}</span>
            </h2>
            <!-- Mega -->
            <div class="DrawNumbersWrap winningNumbersItem wn_mega" v-if="megaMdrawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/mega-millions" class="drawNumbersLogo">
                    <img src= "/MTLLC.Site/media/site-design/MegaMillions_Standard_Logo_RGB.png"
                        class="winNumGameLogo" v-if="this.MegiplierStatus === 'false'" />
                    <img src="/getattachment/b4aae360-6e87-4b5f-a138-9611466aaed7/logo_MegaMillions-Megaplier_282x148.png"
                        class="winNumGameLogo" v-else/>
                </a>
                <div class="winningNumbersContent">
                    <span class="winNumDate">{{ date }}</span>
                    <SpecialWinningNumbers game="mega" :numbers="megaMdrawNumbers" :type="1"></SpecialWinningNumbers>
                </div>
            </div>
            <!-- Powerball -->
            <div class="DrawNumbersWrap winningNumbersItem wn_powerball" v-if="powerBdrawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/powerball" class="drawNumbersLogo">
                    <img src="/getattachment/f49f00e8-72e9-4d66-8a86-a7d4f5e477a5/825_GameLogo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="winningNumbersContent">
                    <span class="winNumDate">{{ date }}</span>
                    <SpecialWinningNumbers game="power" :numbers="powerBdrawNumbers" :type="1"></SpecialWinningNumbers>
                </div>
            </div>
            <!-- Lucy For Life -->
            <div class="DrawNumbersWrap winningNumbersItem wn_lfl" v-if="luckyFLdrawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/lucky-for-life" class="drawNumbersLogo">
                    <img src="/getattachment/3c21ae86-bf24-46fb-acd5-df110fc2322f/832_GameLogo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="winningNumbersContent">
                    <span class="winNumDate">{{ date }}</span>
                    <SpecialWinningNumbers game="lucky" :numbers="luckyFLdrawNumbers" :type="1"></SpecialWinningNumbers>
                </div>
            </div>
            <!-- Lotto Classic -->
            <div class="DrawNumbersWrap winningNumbersItem wn_classic" v-if="classicLdrawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/classic-lotto" class="drawNumbersLogo">
                    <img src="/getattachment/f8a42eb7-2ba1-4f6a-bf8f-5bb9b6ae36b4/classicLotto_logo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="winningNumbersContent">
                    <span class="winNumDate">{{ date }}</span>
                    <SpecialWinningNumbers game="classic" :numbers="classicLdrawNumbers" :type="1"></SpecialWinningNumbers>
                    <div class="kicker-number">
                        <span class="wnLbl">Kicker: </span>
                        <span class="wnVal" v-if="!kickerdrawNumbers.length">Pending</span>
                        <span class="wnVal" v-else>{{ kickerdrawNumbers.map((item) => `${item.value}`).toString().replaceAll(',','') }}</span>
                    </div>
                </div>
            </div>
            <!-- Pick 3 -->
            <div class="DrawNumbersWrap winningNumbersItem wn_pick3" v-if="pick3drawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/pick-3" class="drawNumbersLogo">
                    <img src="/getattachment/f7ed6495-5bc1-4501-8ab3-e7f5579a808b/820_GameLogo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="rm-winningNumbersContent">

                    <span class="winNumDate">{{ customFormatter(pick3drawNumbersMidday[0].drawDate) }}</span>

                    <span class="pickGame">MIDDAY</span>
                    <ul class="winningNumbersList">
                        <li v-for="number in pick3drawNumbersMidday[0].numbers" :key="number.id" class="winNumBall">
                            {{ number.value }}
                        </li>
                    </ul>
                    <span class="pickGame">EVENING</span>
                    <ul class="winningNumbersList">
                        <li v-for="number in pick3drawNumbers[1].numbers" :key="number.id" class="winNumBall">
                            {{ number.value }}
                        </li>
                    </ul>

                </div>
            </div>
            <!-- Pick 4 -->
            <div class="DrawNumbersWrap winningNumbersItem wn_pick4" v-if="pick4drawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/pick-4" class="drawNumbersLogo">
                    <img src="/getattachment/cb83e94f-ab60-4626-b3a1-fec09752b352/821_GameLogo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="rm-winningNumbersContent">
                    <span class="winNumDate">{{ customFormatter(pick4drawNumbersMidday[0].drawDate) }}</span>

                    <span class="pickGame">MIDDAY</span>
                    <ul class="winningNumbersList">
                        <li v-for="number in pick4drawNumbersMidday[0].numbers" :key="number.id" class="winNumBall">
                            {{ number.value }}
                        </li>
                    </ul>
                    <span class="pickGame">EVENING</span>
                    <ul class="winningNumbersList">
                        <li v-for="number in pick4drawNumbers[1].numbers" :key="number.id" class="winNumBall">
                            {{ number.value }}
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Pick 5 -->
            <div class="DrawNumbersWrap winningNumbersItem wn_pick5" v-if="pick5drawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/pick-5" class="drawNumbersLogo">
                    <img src="/getattachment/27f306f4-5511-4b08-a43d-90c387d934be/830_GameLogo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="rm-winningNumbersContent">
                    <span class="winNumDate">{{ customFormatter(pick5drawNumbersMidday[0].drawDate) }}</span>

                    <span class="pickGame">MIDDAY</span>
                    <ul class="winningNumbersList">
                        <li v-for="number in pick5drawNumbersMidday[0].numbers" :key="number.id" class="winNumBall">
                            {{ number.value }}
                        </li>
                    </ul>
                    <span class="pickGame">EVENING</span>
                    <ul class="winningNumbersList">
                        <li v-for="number in pick5drawNumbers[1].numbers" :key="number.id" class="winNumBall">
                            {{ number.value }}
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Rolling Cash 5 -->
            <div class="DrawNumbersWrap winningNumbersItem wn_rc5" v-if="rollingCFdrawNumbers.length">
                <!-- logo -->
                <a href="/games/draw-games/gimme-5" class="drawNumbersLogo">
                    <img src="/getattachment/cf4944be-dab3-432a-bd32-f5a03a53db2c/822_GameLogo.png"
                        class="winNumGameLogo" />
                </a>
                <div class="winningNumbersContent">
                    <span class="winNumDate">{{ date }}</span>
                    <SpecialWinningNumbers game="rcFive" :numbers="rollingCFdrawNumbers" :type="1"></SpecialWinningNumbers>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import WNArchivePicker from '../WinningNumbers/Winning-Numbers-Archive-Picker.vue';
import WinningNumbers from '../../ViewComponents/WinningNumbers/WinningNumbers.vue';
import SpecialWinningNumbers from '../../ViewComponents/WinningNumbers/SpecialWinningNumbers.vue';

export default {
    components: {
        WNArchivePicker,
        WinningNumbers,
        SpecialWinningNumbers
    },
    data: function () {
        return {
            date: '',
            errorMessage: '',
            showError: false,
            pick3drawNumbers: [],
            pick3drawNumbersMidday: [],
            pick4drawNumbers: [],
            pick4drawNumbersMidday: [],
            pick5drawNumbers: [],
            pick5drawNumbersMidday: [],
            megaMdrawNumbers: [],
            powerBdrawNumbers: [],
            luckyFLdrawNumbers: [],
            rollingCFdrawNumbers: [],
            classicLdrawNumbers: [],
            kenodrawNumbers: [],
            kickerdrawNumbers: [],
            timeDay: '',
            showTitle: 0,
            MegiplierStatus: this.getMegiplierStatus(),
        } 
    },
    created: function () {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let urlDate = params.get('date');
        this.date = urlDate;

        this.showTitle = params.size;
    },
    mounted: function () {

        if (this.date != null) {
            this.getToken();
        } else {
            var date = new Date();
            var day = date.getDate();
            var month = date.getMonth() + 1; // Adding 1 to the month to adjust the index
            var year = date.getFullYear();
            var dateString = month + '/' + day + '/' + year;
            this.date = dateString;

            this.getToken();
        }

    },
    methods: {
        customFormatter(date) {
            return moment(new Date(date)).format('MM/DD/YYYY');
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            var apiToken = token;
            var urlProtocol = this.getProtocol();
            var sinceDate = this.date;
            var headers = {
                'Authorization': 'Bearer ' + apiToken,
            }

            Promise.all([
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/Pick3/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/Pick4/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/MegaMillions/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/PowerBall/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/LuckyForLife/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/ClassicLotto/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/Pick5/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/RollingCashFive/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),
                fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/Kicker/SearchByDate?sinceDate=" + encodeURIComponent(sinceDate), {
                    headers,
                }).then(resp => resp.json()),

            ]).then(json => {

                if (json[0].data?.draws[0]) { this.pick3drawNumbers = json[0].data?.draws; }
                if (json[0].data?.draws[1]) { this.pick3drawNumbersMidday = json[0].data?.draws; }
                if (json[1].data?.draws[0]) { this.pick4drawNumbers = json[1].data?.draws; }
                if (json[1].data?.draws[1]) { this.pick4drawNumbersMidday = json[1].data?.draws; }
                if (json[6].data?.draws[0]) { this.pick5drawNumbers = json[6].data?.draws; }
                if (json[6].data?.draws[1]) { this.pick5drawNumbersMidday = json[6].data?.draws; }

                if (json[2].data?.draws[0]) { this.megaMdrawNumbers = json[2].data?.draws[0].numbers; }
                if (json[3].data?.draws[0]) { this.powerBdrawNumbers = json[3].data?.draws[0].numbers; }
                if (json[4].data?.draws[0]) { this.luckyFLdrawNumbers = json[4].data?.draws[0].numbers; }
                if (json[5].data?.draws[0]) { this.classicLdrawNumbers = json[5].data?.draws[0].numbers; }
                if (json[7].data?.draws[0]) { this.rollingCFdrawNumbers = json[7].data?.draws[0].numbers; }
                if (json[8].data?.draws[0]) {this.kickerdrawNumbers = json[8].data?.draws[0].numbers; }

            }).catch(error => {
                // console.error("There was an error!", error);
                return this.errorMessage = error;

            });
        }

    }
};
</script>

<style lang="scss" scoped>
/* style the unique aspects here */
.evening {
    .winNumDate {
        display: none;
    }
}</style>