<template>
    <div class="full">
        <div v-if="featuredVideos.length < 1">
            <p><strong>Check back soon for more Retailer Showcase videos.</strong></p>
        </div>
        <div v-else>
            <div v-if="primaryVideo.length !== ''">
                <h2>Showcase Videos</h2>
                
                    <Transition name="fade" mode="out-in">
                        <div class="transitWrap" :key="primaryVideo[0].articleID">
                            <div class="primary-video responsive_video">
                                <iframe :src="primaryVideo[0].subTitle" frameborder="0"></iframe>
                            </div>
                            <div class="primary-video-details" :key="primaryVideo[0].articleID">
                                <h3>{{ primaryVideo[0].title }}</h3>
                                <p>{{ primaryVideo[0].content }}</p>
                            </div>
                        </div>
                    </Transition>
                
            </div>
            <div v-else class="no-primary-video">
                No primary video selected.
            </div>
            <div class="all-videos">
                <h3>Select a video:</h3>
                <ul class="all-videos-grid">
                    <li v-for="video in featuredVideos" class="all-videos-li" :key="video.articleID">
                        <div class="all-videos-thumb responsive_video">
                            <img :src="video.image1URL" :alt="video.title" />
                        </div>
                        <div class="all-videos-link">
                            <a @click="makePrimary(video.articleID, $event)">{{ video.title }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                primaryVideo: '',
                videos: [
                    {
                        "videoId": 0,
                        "videoUrl": "https://www.youtube.com/embed/grBVpaI_9Yg?si=Lep5oRPr9qiabFbP",
                        "retailerName": "Retailer Spotlight: Whitehall Shell",
                        "retailerDescription": "RN 1 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        "isPrimary": true
                    },
                    {
                        "videoId": 1,
                        "videoUrl": "https://www.youtube.com/embed/3_KgOg_LQ68?si=y_NGhOafoxtXRDem",
                        "retailerName": "Retailer Spotlight: Elf's Sports Bar & Grill",
                        "retailerDescription": "RN 2 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        "isPrimary": false
                    },
                    {
                        "videoId": 2,
                        "videoUrl": "https://www.youtube.com/embed/grBVpaI_9Yg?si=Lep5oRPr9qiabFbP",
                        "retailerName": "Retailer Spotlight: Joe's Party Shop & Drive Thru",
                        "retailerDescription": "RN 3 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        "isPrimary": false
                    },
                    {
                        "videoId": 3,
                        "videoUrl": "https://www.youtube.com/embed/grBVpaI_9Yg?si=Lep5oRPr9qiabFbP",
                        "retailerName": "50th Retailer: Riesbeck's Food Markets",
                        "retailerDescription": "RN 4 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        "isPrimary": false
                    },
                    {
                        "videoId": 4,
                        "videoUrl": "https://www.youtube.com/embed/grBVpaI_9Yg?si=Lep5oRPr9qiabFbP",
                        "retailerName": "Retailer Spotlight: Retailer Name 5",
                        "retailerDescription": "RN 5 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        "isPrimary": false
                    },
                    {
                        "videoId": 5,
                        "videoUrl": "https://www.youtube.com/embed/grBVpaI_9Yg?si=Lep5oRPr9qiabFbP",
                        "retailerName": "50th Retailer: Retailer Name 6",
                        "retailerDescription": "RN 6 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        "isPrimary": false
                    },
                ],
                featuredVideos: ''
            }
        },
        // beforeMount: function() {
        //     this.loadRetailerVideos();
        // },
        mounted: function() {
            this.getToken();
        },
        methods: {
            loadRetailerVideos() {
                //hit the api and get the videos

                //winAmount field translates to field marked Primary Video
                this.primaryVideo = this.featuredVideos.filter((vid) => vid.winAmount === 'true');

                if (this.primaryVideo.length < 1) {
                    let forcePrimaryVideo = [];
                    forcePrimaryVideo.push(this.featuredVideos[0]);
                    //use the first returned video article from the API if none are marked as the Primary Video (by mistake)
                    this.primaryVideo = forcePrimaryVideo;
                }

                console.log("Checking primaryVideo for array with 1 item");
                console.log(this.primaryVideo);
            },
            makePrimary(id, event) {

                if (event) {
                    event.preventDefault();
                }



                console.log(id);
                //change the primary video to whatever video was clicked from the list via ID
                this.primaryVideo = this.featuredVideos.filter((vid) => vid.articleID == id);
            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetAllByCategory/FeaturedVideo", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    }
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    //do any filtering here if need be for splitting video by type of Featured Video
                    this.featuredVideos = results.filter((vid) => vid.teaser === 'Retailer Showcase' );
                    //sort the videos by the date - probably parse date



                    console.log("Real Featured Video Articles");
                    console.log(this.featuredVideos);

                    this.loadRetailerVideos();

                })
                .catch(error => {
                    console.log("Featured Videos API CALL error");
                    console.log(error);
                })
            },
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .no-primary-video {
        background-color: #eee;
        text-align: center;
        font-weight: 700;
        padding: 40px 10px;
        margin-bottom: 20px;
    }
    .primary-video {
        margin-bottom: 20px;
    }
    .primary-video-details {
        margin-bottom: 40px;

        p {
            font-size: 1.6rem;
        }
    }
    ul.all-videos-grid {
        list-style-type: none;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 3.6rem 1.6rem;
    }

    .all-videos h3 {
        margin-bottom: 20px;
    }

    .all-videos-li {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .all-videos-link {
        line-height: 1.2;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    @media only screen and (min-width: 640px) {
        ul.all-videos-grid {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media only screen and (min-width: 1201px) {
        ul.all-videos-grid {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .all-videos-link a {
            font-size: 1.4rem;
        }
    }
</style>