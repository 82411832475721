<template>
    <div v-if="activePoll==null || activePoll==undefined || activePoll =={}" class="poll-outer-wrapper">
        <h2>Poll</h2>
        <p>There are currently no active polls. Please check back soon.</p>
    </div>
    <div v-else class="poll-outer-wrapper">
        <h2>Poll</h2>
        <!-- sending the props to the poll question if active survey exists -->
        <p v-if="activePoll.surveyIdentifier==null || activePoll.surveyIdentifier==undefined">There are currently no active polls. Please check back soon.</p>
      <PollQuestion :kyc-mode="kycMode" :is-user-can-vote="isUserCanVote" :survey-id-prop="surveyId" :fetch-stats="fetchStats" :load-component="loadComponent" :go-verify="goVerify"  :submit-text-prop="submitText" :show-stats-prop="this.showStats" :poll-answers="pollAnswers" :poll-question="pollQuestion" v-else></PollQuestion> 
    </div>
</template>

<script>
    import Vuex from 'vuex';
    import Vue from 'vue';
    import PollQuestion from './PollQuestion.vue';
    //check which poll is active
    Vue.use(Vuex);
    //check if the user has taken it

    export default {
        components: {
            PollQuestion
        },
        data: function () {
            return {
                showStats:'Always',
                activePoll:{},
                pollAnswers:[],
                surveyId:'',
                surveyIdentifier:'',
                pollQuestion:'',
                isUserCanVote:false,
                submitText:'Vote',
                widgetSurveyId: document.getElementById('survey-id'),
                widgetSubmitText: document.getElementById('submit-text'),
                widgetRecentPostsDisplays: document.getElementById('recent-posts-display'),
                kycMode: process.env.VUE_APP_KYCMODE,
            }
        },
     
        mounted: function () {
            this.getToken()
            
        },
        methods: {
            signInClk() {
            $("#globalLogInModal").show();
        },

          getToken() {
          // then hit API with token
          this.getAPItoken().then((token) => {
              this.getActiveSurveys(token);
              this.token = token;
              this.loadComponent();
          });
      },
      
      loadComponent() {
            $(document).ready(function () {
                $('.close-button').on('click', function () {
                    $("#globalLogInModal").hide();
                    $("#globalLoginBlocker").hide();
                })

                $(".btn_popupLogIn").on('click', function () {
                    $("#globalLogInModal").show();
                    $("#globalLoginBlocker").show();
                });
            })
        },
      fetchData(token, id) {
            
            let apiToken = token;
            this.globalToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/GetSurveyByIdentifier?surveyIdentifier=" + id, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            }).then(response => response.json())
                .then(json => {

                    if (json.error) {
                        // console.log("token and id ",token, " and ", id)
                        this.errorMsg = json.error.message
                        // console.log("ERROR ===> ",this.errorMsg)
                     //  document.getElementsByClassName('poll-question-widget')[0].style.display = "none";
                    }else{
                        let results = json.data;
                    // console.log('json ------>>>> ', json);
                    for (var i = 0; i < results.questions.length; i++) {

                        if (results.questions[i].questionType !== 0) {
                            this.errorMsg = "Invalid selected survey, Please contact an administrator"
                            return
                        }
                    }
                    if (results.questions.length >= 2) {
                        this.errorMsg = "Invalid selected survey, Please contact an administrator"
                        return
                    }
                
                    this.pollQuestion = results.questions[0].text;
                    this.pollAnswers = results.questions[0].answers;
                    this.surveyId = results.surveyID;
                    }
                })
                .catch(error => {
                     console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
        },
        fetchStats() {
            // Get survey stats
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/GetSurveyStats?surveyID=" + this.surveyId, {
                headers: {
                    'Authorization': 'Bearer ' + this.globalToken,
                },
            })
                .then(response => response.json())
                .then(json => {

                    let statsResults = json.data;
                    let total = 0,
                        arrOfQuantity = [],
                        percentNumbers = [];


                    let totalQuantity = statsResults.map(a => a.quantity);

                    for (var j = 0; j < totalQuantity.length; j++) {
                        total += totalQuantity[j];
                        arrOfQuantity.push(totalQuantity[j])
                    }

                    arrOfQuantity.forEach((sum) => {
                        let numPercent = Math.round((sum / total) * 100);
                        percentNumbers.push(numPercent);
                    });


                    for (var i = 0; i < statsResults.length; i++) {
                        // console.log( statsResults[i].answerID);
                        // console.log( percentNumbers[i]);
                        document.querySelector('[answer-id="' + statsResults[i].answerID + '"]').insertAdjacentHTML("beforeend",
                            "<span class='width-percent' style='width:" + percentNumbers[i] + "%'></span><span class='text-percent'>" + percentNumbers[i] + "%</span>");
                    }


                })
                .catch(error => {
                    console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
        },
      getActiveSurveys(token) {
        // console.log("api token being sent ",token)
            let apiToken = token;
            this.globalToken = token;
            let getCurrentUserID = this.$cookies.get('apiToken').user.userId;

            //prioritizes widget so if the widget is not on the page itll then fetch the polls
            if(this.widgetSurveyId != null && this.widgetSurveyId != undefined){
                this.surveyId = this.widgetSurveyId;
                this.submitText = this.widgetSubmitText;
                this.showStats = this.widgetRecentPostsDisplays;
                this.fetchData(token, this.widgetSurveyId.value);  
            }else{
            
            // if widget is not on the page it hits the api for the active polls
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/GetAllActiveSurveys?userID=" + getCurrentUserID, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    const data = json.data;
                    //if data comes through sort it
                    this.sortSurveys(data);
                })
                .catch(error => {
                    console.log(error)
                    return this.errorMessage = error;
                });
            }
        },
         sortSurveys (data) {
            let today = new Date();

            let polls = data.filter(poll =>{if(poll.category == 2) return poll;}).sort((a, b) => {
                        if (a.publishedWebFrom > b.publishedWebFrom) return -1;
                        if (a.publishedWebTo < b.publishedWebTo) return 1;
                        return 0;
                    }).filter(poll=>{
                        
                 let publishTo = new Date(poll.publishedWebTo);
                 let publishFrom = new Date(poll.publishedWebFrom);

                if(today > publishFrom && today < publishTo){
                    if(poll.active === true){
                        return poll;
                    }
                }
            });
            
            // console.log('sorted polls ---->',polls);
            
           
            this.activePoll =  polls[0];
           //console.log("poll===> ", this.activePoll)
            this.surveyId = this.activePoll.surveyID;
            if(this.activePoll !== undefined && this.activePoll !== null){
                this.surveyIdentifier = this.activePoll.surveyIdentifier;
                this.fetchData(this.token, this.surveyIdentifier);  
            }
            
            const surveyIDNumb = data.find(survey => survey.surveyID === this.activePoll.surveyID);
            surveyIDNumb ? this.isUserCanVote = true : this.isUserCanVote = false;  
            if (this.activePoll.isSurveyComplete == true){
                    this.isUserCanVote = false;
                }
            // console.log('getActiveSurveys ---', data);
           
         

        },
        goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);

            }
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>

</style>
