<template>
    <div class="">
          
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#0FD8C9"
            v-if="isLoading"
        />

        <div class="press-releases-detail" v-if="showPost">
            <h1>Blog Post</h1>
            <BlogDetail :articleId="articleId"></BlogDetail>
        </div>

        <div class="press-releases-detail" v-if="showRelease">
            <h1>Press Releases</h1>
            <PressReleasesDetailVue :articleId="articleId"></PressReleasesDetailVue>
            
        </div>

        <div class="press-releases-detail" v-if="showRetailer">
            <h1>Retailer-of-the-Week</h1>
            <FeaturedRetailerDetail :articleId="articleId"></FeaturedRetailerDetail>
        </div>

        <div class="press-releases-detail" v-if="showWinner">
            <h1>Featured Winners</h1>
            Need to create a detail component here
            <div class="win-value">
                    {{ winAmount }}
                </div>
                <div class="win-info">
                    <span class="win-date">{{ customFormatter( date ) }}:</span>
                    {{ title }}
                </div>
            </div>

        <div class="press-releases-detail" v-if="showNewAt">
            <h1>New at MyLotto Rewards</h1>
            <div class="featured-rewards-item">
                <div class="featured-rewards-img">
                    <img :src="'/getattachment/'+image1+'/attachment.aspx'" :alt="title"  />
                </div>

                <div class="featured-rewards-cta">
                    <a :href="'${link}'" class="featured-rewards-btn">
                        {{ teaser }}
                    </a>
                </div>

                <div class="featured-rewards-copy">
                    <h3>{{ title }}</h3>
                    <p v-html="content"></p>
                </div>
            </div>
        </div>

        <div v-if="showErrorMsg">{{ errorMsg }}</div>

    </div>
</template>

<script>
    import { HollowDotsSpinner } from 'epic-spinners';
    import PressReleasesDetailVue from '../PressReleases/PressReleasesDetail.vue';
    import FeaturedRetailerDetail from '../FeaturedRetailer/FeaturedRetailer/FeaturedRetailerDetail.vue';
    import BlogDetail from '../../ViewComponents/Blog/BlogDetail.vue';
    import moment from 'moment';

    export default {
        components: {
            BlogDetail,
            HollowDotsSpinner,
            PressReleasesDetailVue,
            FeaturedRetailerDetail,
        },
        data: function () {
            return {
                showResults: false,
                showErrorMsg: false,
                errorMsg: '',
                isLoading: false,
                articleId: '',
                articleCat: '',
                showPost: false,
                showRelease: false,
                showRetailer: false,
                showWinner: false,
                showNewAt: false,
                title: '',
                content: '',
                link: '',
                image1: '',
                teaser: '',
                winAmount: '',
                date: '',
            }
        },
        mounted: function () {
            this.isLoading = true;
            this.getArticleData();
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MMMM Do');
            },
            getArticleData() {
                let articleIdVal = document.getElementById('articleid').value;
                let articleCatVal = document.getElementById('articlecat').value;

                // console.log(articleIdVal, articleCatVal);

                if (articleIdVal != '' && articleIdVal != null && articleIdVal != undefined && articleCatVal != '' && articleCatVal != null && articleCatVal != undefined) {
                    this.articleId = articleIdVal;
                    this.articleCat = articleCatVal;
                }
                // catch the else and prevent further processing, maybe show error

                if (this.articleCat == '7906f294-2885-4b44-aac3-920b232de2c4') {
                    // Blog Post
                    this.showPost = true;
                    this.isLoading = false;
                    
                } else if (this.articleCat.toLowerCase() == '4e1b9e73-651c-4317-9fbc-7f4696f810fe') {   
                    // Press Releases
                    this.showRelease = true;
                    this.isLoading = false;
                    
                } else if (this.articleCat == '2b1ff45c-8751-4360-b895-1a8a20709b9b') {
                    // Retailer-of-the-Week
                    this.showRetailer = true;
                    this.isLoading = false;
                    
                } else if (this.articleCat == 'd0407eb8-bc5b-4663-8a55-358bd06667ed') {
                    // Featured Winner
                    this.showWinner = true;
                    this.getToken();
                    
                } else if (this.articleCat == 'aa58b714-8f77-4c9e-b9c2-846937c8d44a') {
                    // New at MyLotto Rewards
                    this.showNewAt = true;
                    this.getToken();
                    
                }
                
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    id = this.articleId;
                    
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetById/"+id, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let res = json.data;
                    this.title = res.title;
                    this.subtitle = res.subtitle;
                    this.content = res.content;
                    this.image1 = res.image1;
                    this.date = res.date;                    
                    this.link = res.link;
                    this.teaser = res.teaser;
                    this.winAmount = res.winAmount;

                    this.isLoading = false;

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    this.showErrorMsg = true;
                    this.errorMsg = error;
                    this.isLoading = false;

                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


</style>